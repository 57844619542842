$publicPath:'';

@import "./../../../assets/css/global-variable.scss";
.g-user-add-box {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.m-crop {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  cursor: pointer;
  &[readonly] {
    pointer-events: none;
  }
  .u-img {
    width: 100%;
    height: 100%;
  }
  .u-icon {
    font-size: 24px;
    color: $fc-secondary;
  }
}
